import React from 'react';
import PropTypes from 'prop-types';
import { useRecordContext, useTranslate } from 'react-admin';
import { List, ListItem, ListItemAvatar, ListItemText, Divider, Avatar } from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import ApiIcon from '@mui/icons-material/Api';
import PollieIcon from './layout/PollieIcon';
import { useTheme } from '@mui/material/styles';

const StructuredJsonField = ({ source }) => {
  const record = useRecordContext();
  const data = JSON.parse(record[source]).slice(1);
  const theme = useTheme();
  const roleColors = theme.customComponents?.StructuredJsonField?.roles;

  const getRoleDetails = (role) => {
    const roleConfig = roleColors?.[role] || roleColors?.default;
    
    const iconMap = {
      assistant: <PollieIcon fontSize='small' />,
      user: <LocalPhoneIcon fontSize='small' />,
      system: <AutoAwesomeIcon fontSize='small' />,
      tool: <ApiIcon fontSize='small' />,
      default: <PollieIcon fontSize='small' />
    };

    return {
      bgColor: roleConfig?.bgColor,
      textColor: roleConfig?.textColor,
      avatarColor: roleConfig?.avatarColor,
      icon: iconMap[role] || iconMap.default
    };
  };
  

  if (!Array.isArray(data)) {
    console.error('Data is not an array:', data);
    return <div>Error: Data is not an array</div>;
  }

  const renderToolCalls = (toolCalls: any, messages: any) => {
    return toolCalls.map((call: any, idx: any) => {
      const response = messages.find((msg: any) => msg.role === "tool" && msg.tool_call_id === call.id);
  
      // Functie om een object te formatteren naar een string met elke key-value op een nieuwe regel
      function formatArguments(args) {
        try {
          const parsedArgs = JSON.parse(args);
          // Controleer of het resultaat een object is
          if (Object.prototype.toString.call(parsedArgs) === "[object Object]") {
            return Object.entries(parsedArgs)
              .map(([key, value]) => `${key}: ${value}`)  // Map elke key-value pair naar een string
              .join('\n');  // Voeg elke pair toe op een nieuwe regel
          } else {
            // Als het geparseerde argument geen object is, geef de originele string terug
            return args;
          }
        } catch (error) {
          // Valt terug op de originele string als het parsen faalt
          return args;
        }
      }

      // Gebruik deze functie in je bestaande code:
      let formattedArguments;
      formattedArguments = formatArguments(call.function.arguments);

  
      return (
        <div key={idx} style={{fontSize: '12px'}}>
          <div><strong>{call.function.name}</strong>: <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', overflowWrap: 'break-word' }}>{formattedArguments}</pre></div>
          <div><strong>response:</strong> <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', overflowWrap: 'break-word' }}>{response ? response.content : 'No response found'}</pre></div>
        </div>
      );
    });
  };

  function truncateAfterText(content: string, searchText: string) {
    const endIndex = content.indexOf(searchText);
    if (endIndex !== -1) {
      // Voeg de lengte van searchText toe om de tekst inclusief 'The call has ended' te behouden
      return content.substring(0, endIndex + searchText.length);
    }
    return content;
  }

  const processData = (data) => {
    return data.map(msg => {
      if (msg.tool_calls && msg.role === 'assistant') {
        return { ...msg, role: 'tool' };
      }
      if (msg.role === 'assistant' && msg.content === '' && !msg.tool_calls) {
        return null; // Geef null terug om aan te geven dat dit item verwijderd moet worden
      }
      return msg;
    }).filter(Boolean); // Filtert alle null-waarden uit de array
  };

  const dataWithAdjustedRoles = processData(data);
  const filteredData = dataWithAdjustedRoles.filter(msg => !(msg.role === "tool" && data.some(d => d.tool_calls?.some(tc => tc.id === msg.tool_call_id))));

    return (
      <List sx={{ width: '100%', overflow: 'hidden', padding: 0, margin: 0 }}>
        {filteredData.map((msg, index) => (
          <React.Fragment key={index}>
<ListItem alignItems="flex-start" sx={{ bgcolor: getRoleDetails(msg.role).bgColor }}>
  <ListItemAvatar>
    <Avatar 
      title={msg.role} 
      sx={{ 
        width: 24, 
        height: 24, 
        fontSize: '0.75rem', 
        bgcolor: getRoleDetails(msg.role).avatarColor
      }}
    >
      {getRoleDetails(msg.role).icon}
    </Avatar>
  </ListItemAvatar>
  <ListItemText                
    primary={
      <>
        <span style={{ color: getRoleDetails(msg.role).textColor }}>
          {msg.tool_calls ? renderToolCalls(msg.tool_calls, data) : 
          msg.role === 'function' ? msg.name + ' => ' + msg.content : truncateAfterText(msg.content,'The call has ended')}
        </span>
        {/* Hier kun je meer informatie toevoegen indien nodig */}
      </>
    }
  />
</ListItem>

            {index < data.length - 1 && <Divider component="li" />}
          </React.Fragment>
        ))}
      </List>
    );
};

StructuredJsonField.propTypes = {
  source: PropTypes.string.isRequired,
};

export default StructuredJsonField;

import React, { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import Inbox from '@mui/icons-material/Inbox';
import { useTranslate } from 'ra-core';
import { CreateButton } from 'react-admin';
import { useUserContext } from '../users';

const PREFIX = 'RaEmpty';
export const EmptyClasses = {
    message: `${PREFIX}-message`,
    icon: `${PREFIX}-icon`,
    toolbar: `${PREFIX}-toolbar`,
};

const Root = styled('div')(({ theme }) => ({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [`& .${EmptyClasses.message}`]: {
        textAlign: 'center',
        opacity: theme.palette.mode === 'light' ? 0.5 : 0.8,
        margin: '0 1em',
        color: theme.palette.mode === 'light' ? 'inherit' : theme.palette.text.primary,
    },
    [`& .${EmptyClasses.icon}`]: {
        width: '9em',
        height: '9em',
        marginBottom: theme.spacing(2),
    },
    [`& .${EmptyClasses.toolbar}`]: {
        marginTop: theme.spacing(2),
    },
}));

const EmptyUser = ({ translate }: { translate: (key: string) => string }) => {
    const { getCreatePath } = useUserContext();
    const createPath = useMemo(() => getCreatePath(), [getCreatePath]);

    return (
        <Root>
            <div className={EmptyClasses.message}>
                <Inbox className={EmptyClasses.icon} />
                <Typography variant="h4" paragraph>
                    {translate('resources.users.empty')}
                </Typography>
                    <Typography variant="body1">
                        {translate('resources.users.invite')}
                    </Typography>
            </div>
                <div className={EmptyClasses.toolbar}>
                    <CreateButton variant="contained" to={createPath} />
                </div>
        </Root>
    );
};

export default React.memo(EmptyUser);
import { useTranslate, TextInput } from "react-admin";

const PhoneNumberInput = (props: any) => {
    const translate = useTranslate();

    const validatePhone = (value: string) => {
        if (!value) return undefined;

        const e164Regex = /^\+[1-9]\d{1,14}$/;
        if (!e164Regex.test(value)) {
            return translate('app.validation.invalid_phone');
        }

        if (value.length < 9) {
            return translate('app.validation.phone_too_short');
        }

        return undefined;
    };

    return (
        <TextInput
            {...props}
            validate={validatePhone}
            placeholder="+1234567890"
            helperText={translate('app.validation.phone_format')}
            type="tel"
            autoComplete="tel"
        />
    );
};

export default PhoneNumberInput;
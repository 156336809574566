import { useTranslate } from "react-admin";
import { Chip, Box, Typography } from "@mui/material";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AssistantIcon from '@mui/icons-material/Assistant';
import BusinessIcon from '@mui/icons-material/Business';

interface ActiveFiltersProps {
  filters: {
    start_date?: string;
    end_date?: string;
    shortcutLabel?: string;
    assistant_ids?: string[];
    customer_ids?: string[];
  };
  onFilterChange: (filters: any) => void;
  onOpenFilters: (filterName: string) => void;
}

const ClickableText = ({ onClick, children }: { onClick: () => void, children: React.ReactNode }) => (
  <Box
    component="span"
    onClick={onClick}
    sx={{
      fontWeight: 'bold',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    }}
  >
    {children}
  </Box>
);

export const ActiveFilters = ({ filters, onFilterChange, onOpenFilters }: ActiveFiltersProps) => {
  const translate = useTranslate();

  const createFilterChip = (label: string, icon: JSX.Element, onDelete: () => void) => (
    <Chip
      label={label}
      icon={icon}
      onDelete={onDelete}
      variant="outlined"
      size="small"
      sx={{ mx: 0.5 }}
    />
  );

  const getDateRangeFilter = () => {
    if (filters.start_date) {
        if (filters.shortcutLabel) {
            return createFilterChip(
                translate(filters.shortcutLabel),
                <CalendarTodayIcon />,
                () => onFilterChange({ 
                    start_date: undefined, 
                    end_date: undefined,
                    shortcutLabel: undefined 
                })
            );
        }
        
        const startDate = new Date(filters.start_date).toLocaleDateString();
        const endDate = filters.end_date 
            ? new Date(filters.end_date).toLocaleDateString()
            : startDate;
        return createFilterChip(
            `${startDate} - ${endDate}`,
            <CalendarTodayIcon />,
            () => onFilterChange({ 
                start_date: undefined, 
                end_date: undefined,
                shortcutLabel: undefined 
            })
        );
    }
    return (
        <ClickableText onClick={() => onOpenFilters('date')}>
            {translate('resources.generic.date_shortcuts.last_30_days').toLowerCase()}
        </ClickableText>
    );
  };

  const getAssistantsFilter = () => {
    if (filters.assistant_ids?.length) {
      return createFilterChip(
        `${filters.assistant_ids.length} ${translate("resources.assistants.name", { 
          smart_count: filters.assistant_ids.length 
        })}`,
        <AssistantIcon />,
        () => onFilterChange({ assistant_ids: undefined })
      );
    }
    return (
      <ClickableText onClick={() => onOpenFilters('assistants')}>
        {translate('resources.generic.all_assistants')}
      </ClickableText>
    );
  };

  const getCustomersFilter = () => {
    if (filters.customer_ids?.length) {
      return createFilterChip(
        `${filters.customer_ids.length} ${translate("resources.customers.name", { 
          smart_count: filters.customer_ids.length 
        })}`,
        <BusinessIcon />,
        () => onFilterChange({ customer_ids: undefined })
      );
    }
    return null;
  };

  return (
    <Box>
      <Typography 
        variant="subtitle1"
        sx={{ 
          color: 'text.primary',
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: 0.5,
          m: 0
        }}
      >
        {translate('resources.generic.showing_data')} {getAssistantsFilter()}
        {filters.customer_ids?.length && (
          <>{translate('resources.generic.at')} {getCustomersFilter()}</>
        )}
        {translate('resources.generic.over')} {getDateRangeFilter()}
      </Typography>
    </Box>
  );
}; 
import React, { useEffect, useState } from 'react';
import { IconButton } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import { useLocation } from 'react-router-dom';

// Globale audio variabele
export let audio: HTMLAudioElement | null = null;

export const PlayAudioButton = ({ endpoint, params }: { endpoint: string; params: any; }) => {
    // State toevoegen voor het bijhouden van de afspeelstatus
    const [isPlaying, setIsPlaying] = useState(false);
    const location = useLocation(); // Haal de huidige locatie op met React Router

    const playAudio = async () => {
        const apiUrl = import.meta.env.VITE_API_URL;
        const fullUrl = apiUrl + endpoint;
        const auth = localStorage.getItem('auth');
        const token = auth ? JSON.parse(auth).access_token : null;

        try {
            // Stop de huidige audio indien deze speelt en wijzig de afspeelstatus
            if (audio) {
                audio.pause();
                audio.currentTime = 0;
                setIsPlaying(false);
            }

            const response = await fetch(fullUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(params)
            });
            const audioBlob = await response.blob();
            const audioUrl = URL.createObjectURL(audioBlob);
            audio = new Audio(audioUrl);
            audio.play();
            setIsPlaying(true); // Update de afspeelstatus


            // Voeg een event listener toe om de state bij te werken als de audio eindigt
            audio.addEventListener('ended', () => setIsPlaying(false));
        } catch (error) {
            console.error('Fout bij het afspelen van audio:', error);
        }
    };

    const stopAudio = () => {
        if (audio) {
            audio.pause();
            audio.currentTime = 0;
            setIsPlaying(false); // Update de afspeelstatus
        }
    };

    useEffect(() => {
        // Stop de audio wanneer de locatie verandert (d.w.z. de gebruiker navigeert weg)
        return () => {
            if (audio) {
                audio.pause();
                audio.currentTime = 0;
                setIsPlaying(false);
            }
        };
    }, [location]);

    // Wijzig de onClick handler en het icoon op basis van de afspeelstatus
    return (
        <IconButton onClick={isPlaying ? stopAudio : playAudio} color="primary" size="large">
            {isPlaying ? <StopIcon /> : <PlayArrowIcon />}
        </IconButton>
    );
};

import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useTranslate, useLocaleState } from 'react-admin';
import { useEffect, useState, useRef } from 'react';
import 'dayjs/locale/nl';
import 'dayjs/locale/en';

interface DateRangeFilterProps {
    onChange: (dates: { start?: string; end?: string; shortcutLabel?: string }) => void;
    value?: [Date, Date];
}

export const DateRangeFilter = ({ onChange, value }: DateRangeFilterProps) => {
    const translate = useTranslate();
    const [locale] = useLocaleState();  
    const safeLocale = locale === 'nl_NL' ? 'nl' : 'en';

    const [selectedDates, setSelectedDates] = useState<[dayjs.Dayjs | null, dayjs.Dayjs | null]>(() => 
        value ? [dayjs(value[0]), dayjs(value[1])] : [null, null]
    );
    const isShortcutRef = useRef(false);

    useEffect(() => {
        if (value) {
            setSelectedDates([dayjs(value[0]), dayjs(value[1])]);
        }
    }, [value]);

    const handleDateRangeChange = (
        newValue: [dayjs.Dayjs | null, dayjs.Dayjs | null],
        context?: any
    ) => {
        setSelectedDates(newValue);
        if (newValue[0] && context?.shortcut) {
            isShortcutRef.current = true;
            const updatedFilters = {
                start: newValue[0].toISOString(),
                end: newValue[1]?.toISOString() || newValue[0].toISOString(),
                shortcutLabel: context.shortcut.id
            };
            onChange(updatedFilters);
        }
    };

    const handleAccept = (newValue: [dayjs.Dayjs | null, dayjs.Dayjs | null]) => {
        if (newValue[0] && !isShortcutRef.current) {
            const updatedFilters = {
                start: newValue[0].toISOString(),
                end: newValue[1]?.toISOString() || newValue[0].toISOString(),
                shortcutLabel: undefined
            };
            onChange(updatedFilters);
        }
        
        isShortcutRef.current = false;
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={safeLocale}>
            <DateRangePicker
                value={selectedDates}
                onChange={handleDateRangeChange}
                onAccept={handleAccept}
                maxDate={dayjs().subtract(1, 'day')}
                slotProps={{
                    shortcuts: {
                        items: [
                            {
                                label: translate('resources.generic.date_shortcuts.last_7_days'),
                                id: 'resources.generic.date_shortcuts.last_7_days',
                                getValue: () => {
                                    return [
                                        dayjs().subtract(8, 'day'),
                                        dayjs().subtract(1, 'day')
                                    ];
                                },
                            },
                            {
                                label: translate('resources.generic.date_shortcuts.last_30_days'),
                                id: 'resources.generic.date_shortcuts.last_30_days',
                                getValue: () => {
                                    return [
                                        dayjs().subtract(31, 'day'),
                                        dayjs().subtract(1, 'day')
                                    ];
                                },
                            },
                            {
                                label: translate('resources.generic.date_shortcuts.last_90_days'),
                                id: 'resources.generic.date_shortcuts.last_90_days',
                                getValue: () => {
                                    return [
                                        dayjs().subtract(91, 'day'),
                                        dayjs().subtract(1, 'day')
                                    ];
                                },
                            },
                            {
                                label: translate('resources.generic.date_shortcuts.this_year'),
                                id: 'resources.generic.date_shortcuts.this_year',
                                getValue: () => {
                                    return [
                                        dayjs().startOf('year').subtract(1, 'day'),
                                        dayjs().subtract(1, 'day')                                        
                                    ];
                                },
                            },
                            {
                                label: translate('resources.generic.date_shortcuts.last_365_days'),
                                id: 'resources.generic.date_shortcuts.last_365_days',
                                getValue: () => {
                                    return [
                                        dayjs().subtract(366, 'day'),
                                        dayjs().subtract(1, 'day')
                                    ];
                                }
                            }
                        ]
                    }
                }}
            />
        </LocalizationProvider>
    );
}; 
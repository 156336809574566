import { memo, ReactElement } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Link } from 'react-router-dom';
import { stringify } from 'query-string';
import { useResourceContext, useRecordContext, useCreatePath } from 'ra-core';
import { Button, ButtonProps } from 'react-admin';

export const CustomCloneButton = ({
    icon = defaultIcon,
    scrollToTop = true,
    excludeFields = [],
    label = 'ra.action.clone',
    resource: resourceProp,
    record: recordProp,
    ...rest
}: CustomCloneButtonProps) => {
    const resourceFromContext = useResourceContext();
    const recordFromContext = useRecordContext();
    const createPath = useCreatePath();
    
    const resource = resourceProp || resourceFromContext;
    const record = recordProp || recordFromContext;
    
    const pathname = createPath({ resource, type: 'create' });

    const customOmitFields = (record: any) => {
        const newRecord = { ...record };
        excludeFields.forEach(field => delete newRecord[field]);
        return newRecord;
    };

    return (
        <Button
            component={Link}
            to={
                record
                    ? {
                          pathname,
                          search: stringify({
                              source: JSON.stringify(customOmitFields(record)),
                          }),
                      }
                    : pathname
            }
            state={{ _scrollToTop: scrollToTop }}
            label={label}
            onClick={stopPropagation}
            {...rest}
        >
            {icon}
        </Button>
    );
};

const defaultIcon = <ContentCopyIcon />;
const stopPropagation = (e: any) => e.stopPropagation();

interface CustomCloneButtonProps extends ButtonProps {
    icon?: ReactElement;
    scrollToTop?: boolean;
    excludeFields?: string[];
    resource?: string;
    record?: any;
}

export default memo(CustomCloneButton);

import { Create, Edit, SimpleForm, Datagrid, List, TextField, TextInput } from 'react-admin';
import CustomMDXEditor from './CustomMdxEditor';


export const PromptTemplateList = () => (
    <List exporter={false}>
        <Datagrid rowClick="show" bulkActionButtons={false}>
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="description" />
        </Datagrid>
    </List>
    );

export const PromptTemplateEdit = () => {
    
    return (
        <Edit mutationMode='optimistic'>
            <SimpleForm>
                <TextInput source="name" />
                <TextInput source="description" fullWidth />
                <CustomMDXEditor source="content" />

            </SimpleForm>
        </Edit>
    );
}

export const PromptTemplateCreate = () => {

    return (
        <Create>
            <SimpleForm>
                <TextInput source="name" />
                <TextInput source="description" fullWidth />
                <CustomMDXEditor source="content" />
            </SimpleForm>
        </Create>
    );
}



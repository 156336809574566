import { useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import {
    Card,
    Box,
    Button,
    Typography,
    InputLabel,
} from '@mui/material';
import { useNotify, useTranslate, Form, useLogin, useDataProvider } from 'react-admin';
import LogoAlt from './layout/LogoAlt';
import PasswordInput from './PasswordInput';

interface FormValues {
    password?: string;
    confirmPassword?: string;
}

export const ResetPassword = () => {
    const [loading, setLoading] = useState(false);
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');
    const navigate = useNavigate();
    const notify = useNotify();
    const translate = useTranslate();
    const dataProvider = useDataProvider();

    if (!token) {
        notify('app.auth.errors.auth.INVALID_RESET_TOKEN', { type: 'error' });
        navigate('/login');
        return null;
    }

    const handleSubmit = async (values: FormValues) => {
        setLoading(true);

        if (values.password !== values.confirmPassword) {
            notify('app.auth.errors.auth.PASSWORDS_DO_NOT_MATCH', { type: 'error' });
            setLoading(false);
            return;
        }

        try {
            const response = await dataProvider.custom('reset-password', {
                postdata: {
                    token,
                    new_password: values.password,
                }
            });

            notify('app.auth.password_reset_success', { type: 'success' });
            navigate('/login');
        } catch (error: any) {
            const errorMessage = error?.body?.errors?.[0]?.code || 'app.auth.generic_error';
            notify(errorMessage, { type: 'error' });
            if (errorMessage === 'INVALID_RESET_TOKEN') {
                navigate('/login');
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <Form onSubmit={handleSubmit} noValidate>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#F7F7F2'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        transform: 'translateY(-15%)',
                    }}
                >
                    <Box
                        sx={{
                            marginBottom: '2em',
                            transform: 'scale(0.75)',
                        }}
                    >
                        <LogoAlt />
                    </Box>
                    <Card sx={{ minWidth: 400, padding: '3em' }}>
                        <Typography 
                            variant="h6"
                            sx={{
                                marginBottom: '1em',
                                fontWeight: 'bold',
                                textAlign: 'center',
                                color: '#172121',
                                fontSize: '1.1rem',
                            }}
                        >
                            {translate('app.auth.reset_password')}
                        </Typography>

                        <Box sx={{ marginBottom: '-0.5em' }}>
                            <InputLabel
                                htmlFor="password"
                                sx={{
                                    marginBottom: '0em',
                                    color: '#172121',
                                    fontWeight: 500,
                                    fontSize: '0.9rem',
                                    textAlign: 'left',
                                    display: 'block',
                                    width: '100%'
                                }}
                            >
                                {translate('app.auth.new_password')}
                            </InputLabel>
                            <PasswordInput
                                source="password"
                                mode="reset"
                                label=""
                                disabled={loading}
                                fullWidth
                                helperText={false}
                                placeholder={translate('app.auth.new_password')}
                            />
                        </Box>

                        <Box sx={{ marginBottom: 'em' }}>
                            <InputLabel
                                htmlFor="confirmPassword"
                                sx={{
                                    marginBottom: '0em',
                                    color: '#172121',
                                    fontWeight: 500,
                                    fontSize: '0.9rem',
                                    textAlign: 'left',
                                    display: 'block',
                                    width: '100%'
                                }}
                            >
                                {translate('app.auth.confirm_password')}
                            </InputLabel>
                            <PasswordInput
                                source="confirmPassword"
                                mode="confirmation"
                                label=""
                                disabled={loading}
                                fullWidth
                                helperText={false}
                                placeholder={translate('app.auth.confirm_password')}
                            />
                        </Box>

                        <Box sx={{ marginBottom: 'em' }}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={loading}
                                fullWidth
                                sx={{ mt: 2, mb: 2 }}
                            >
                                {translate('app.auth.reset_password')}
                            </Button>
                        </Box>
                    </Card>
                </Box>
            </Box>
        </Form>
    );
}; 
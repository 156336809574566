import * as React from 'react';
import { MouseEvent, useState } from 'react';
import { useLocaleState, useLocales, useGetIdentity, useDataProvider } from 'react-admin';
import { Button, Menu, MenuItem } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Translate';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const CustomLocalesMenuButton = (props: CustomLocalesMenuButtonProps) => {
    const { icon = <LanguageIcon />, languages: languagesProp } = props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const languages = useLocales({ locales: languagesProp });
    const [locale, setLocale] = useLocaleState();
    const { identity } = useGetIdentity();
    const dataProvider = useDataProvider();

    const updateBackendLocale = async (newLocale: string) => {
        try {
            await dataProvider.custom('me/change-locale', {
                method: 'POST',
                data: { locale: newLocale },
            });
        } catch (error) {
            console.error("Fout bij het bijwerken van de taal in de backend", error);
        }
    };

    const getNameForLocale = (locale: string): string => {
        const language = languages.find(language => language.locale === locale);
        return language ? language.name : locale;
    };

    const changeLocale = (newLocale: string) => async () => {
        if (identity?.id) {
            await updateBackendLocale(newLocale);
            setLocale(newLocale);
        }
        setAnchorEl(null);
    };

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button
                color="inherit"
                onClick={handleClick}
                startIcon={icon}
                endIcon={<ExpandMoreIcon />}
            >
                {getNameForLocale(locale)}
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {languages.map(language => (
                    <MenuItem
                        key={language.locale}
                        onClick={changeLocale(language.locale)}
                        selected={language.locale === locale}
                    >
                        {language.name}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

interface CustomLocalesMenuButtonProps {
    icon?: React.ReactNode;
    languages?: { locale: string; name: string }[];
}

import React, { useState, useEffect } from 'react';
import { Chip, Autocomplete, TextField, Grid, Typography } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { useNotify, useDataProvider, useTranslate, usePermissions } from 'react-admin';
import Emoji from 'react-emoji-render'; 

interface TagsManagerProps {
  record?: Record<string, any>;
  isEditable?: boolean;
  label?: string;
}

interface Tags {
  [key: string]: string;
}

const basicTags = {
  ':thumbs-up:': ':thumbs_up:',
  ':thumbs-down:': ':thumbs_down:',
};

const adminTags = {
  'needs-review': ':right-pointing_magnifying_glass:',
  'reviewed': ':check_mark_button:',
  'finetuning': ':wrench:',
  'example': ':sports_medal:',
};

const allTags = Object.assign({}, basicTags, adminTags)

const TagsManager: React.FC<TagsManagerProps> = ({ record, label, isEditable = true }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const { permissions } = usePermissions();
  const [tags, setTags] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState('');

  const isAdmin = ['admin', 'partner'].includes(permissions);

  const tagList = isAdmin ? Object.assign({}, basicTags, adminTags) : basicTags;
  
  const availableTags = Object.keys(tagList);

  useEffect(() => {
    if (record && record.tags) {
      // Filter out non-basic tags for non-admin users
      const filteredTags = isAdmin 
        ? record.tags 
        : record.tags.filter((tag: string) => tag in basicTags);
      setTags(filteredTags);
    } else {
      setTags([]);
    }
  }, [record, isAdmin]);


  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (document.activeElement === document.body && isEditable) {
        const key = event.key;
        if (['1', '2', '3', '4', '5'].includes(key)) {
          event.preventDefault();
          const tagNumber = parseInt(key);
          const tagName = `rating-${tagNumber}`;
          handleRatingTag(tagName);
        }
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [tags, isEditable]);

  const handleRatingTag = (newTag: string) => {
    const ratingTags = ['rating-1', 'rating-2', 'rating-3', 'rating-4', 'rating-5'];
    let updatedTags = tags.filter(tag => !ratingTags.includes(tag));
    
    if (!tags.includes(newTag)) {
      updatedTags.push(newTag);
    }

    setTags(updatedTags);
    saveTags(updatedTags);
  };

  const handleAddTag = (event: React.SyntheticEvent | null, newValue: string | null) => {
    if (newValue && !tags.includes(newValue) && !['rating-1', 'rating-2', 'rating-3', 'rating-4', 'rating-5'].includes(newValue)) {
      const updatedTags = [...tags, newValue];
      setTags(updatedTags);
      saveTags(updatedTags);
    }
  };

  const handleDeleteTag = (tagToDelete: string) => {
    const updatedTags = tags.filter(tag => tag !== tagToDelete);
    setTags(updatedTags);
    saveTags(updatedTags);
  };

  const saveTags = (updatedTags: string[]) => {
    if (record) {
      dataProvider.update('conversations', { id: record.id, data: { tags: updatedTags }, previousData: record })
        .then(() => {
          notify(`resources.conversations.tag_updated`, { type: 'success' });
        })
        .catch((error) => {
          notify(`Fout bij het bijwerken van tags: ${error.message}`, { type: 'error' });
        });
    }
  };

  const renderTagLabel = (tag: string) => {
    return tagList[tag] ? <Emoji text={tagList[tag]} /> : tag;
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12}>
        {label && (
          <Typography component="p" variant="body1" className="RaLabeled-label css-bv1hqa-MuiTypography-root">
            <span>{label}</span>
          </Typography>
        )}
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
          {tags.map((tag, index) => (
            <Chip
              key={index}
              title={tag}
              label={renderTagLabel(tag)}
              onDelete={isEditable ? () => handleDeleteTag(tag) : undefined}
              color="default"
              size="small"
              deleteIcon={<CancelIcon />}
              style={{ backgroundColor: '#eeeeee' }}
            />
          ))}
        </div>
      </Grid>
      {isEditable && (
        <Grid item xs={12} sm={6} md={4}>
          <Autocomplete
            value={null}
            onChange={(event, newValue) => {
              handleAddTag(event, newValue);
              setInputValue('');
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            options={availableTags}
            freeSolo
            isOptionEqualToValue={(option, value) => option === value}
            renderInput={(params) => (
              <TextField
                {...params}
                label={translate('resources.conversations.add_tag')}
                variant="outlined"
                margin="dense"
              />
            )}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default TagsManager;

export { allTags };
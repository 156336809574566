import { defaultTheme, defaultDarkTheme } from 'react-admin';
import { deepmerge } from '@mui/utils';

export const myTheme = deepmerge(defaultTheme, {
    components: {
        ...defaultTheme.components,
        MuiTextField: {
            defaultProps: {
                variant: 'outlined',
            },
            styleOverrides: {
                root: {
                    '&.custom-multiline .MuiInputBase-inputMultiline': {
                        minHeight: '50px', // Deze stijl zal alleen toegepast worden op textareas met de 'custom-min-height' klasse
                        fontSize: '1rem',
                    }                 
                }
            }            
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    boxShadow: 'none', // remove app bar shadow
                    textTransform: 'capitalize',
                },
            }
        },
        RaSidebar: {
            styleOverrides: {
                docked: {
                    display: 'none'
                }
            }
        },
        // override default menu item state
        RaMenuItemLink: {
            styleOverrides: {
                root: {
                    color: '#172121', // Donkerblauwe kleur voor alle buttons
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {        
                    // Standaard stijlen voor MuiMenuItem
                    '&:hover': {
                        color: 'rgba(252, 128, 119, 0.8)', // Roze kleur met lichte opacity voor hover
                        backgroundColor: 'transparent !important', // Geen achtergrondkleur bij hover                            
                    },
                    // Specifieke stijlen voor geselecteerde menu-items
                    '&.RaMenuItemLink-active': {
                        color: '#FC8077 !important', // Roze kleur voor actieve items
                        backgroundColor: 'rgba(252, 128, 119, 0.10)',
                        fontWeight: 'bold',
                        '&:hover': {
                            color: 'rgba(252, 128, 119, 1) !important', // Volle roze kleur voor hover op actieve items
                        },
                    },
                },
            },
                        
        },
        MuiDrawer: {
            styleOverrides: {
                docked: {
                    borderRight: '1px solid rgba(0, 0, 0, 0.12)', // Voeg een rechterrand toe
                    marginRight: '10px',
                },
            },
        },        
        // remove appbar shadow
        MuiAppBar: {
            styleOverrides: {
                root: {
                    boxShadow: 'none', 
                    borderBottom: '1px solid rgba(0, 0, 0, 0.12)', 
                },
            },
        },
        // remove paper shadow and table borders
        MuiPaper: {
            styleOverrides: {
                root: {
                    border: 'none', // Verwijder de linker rand
                    boxShadow: 'none', // remove app ba
                    backgroundColor: '#ffffff',
                },
            },
        },
        // column headers in bold
        MuiTableCell: {
            styleOverrides: {
                head: { // 
                    fontWeight: 'bold',
                    borderBottom: '2px solid rgba(0, 0, 0, 0.22)', // Voeg een 2px dikke onderste rand toe
                },
            },
        },            
    },
    sidebar: {
        width: 210, 
        closedWidth: 55, 
    },
    palette: {
        primary: {
            main: '#368F8B', 
        },
        background: {
            default: '#ffffff',
        },
        secondary: {
            main: '#ffffff',
            contrastText: '#141c1c', 
        },
        text: {
            primary: '#172121', 
        },                
    },
    typography: {
        // Use the system font instead of the default Roboto font.
        fontFamily: ['-apple-system', 'Figtree', '"Segoe UI"', 'Arial', 'sans-serif'].join(','),
    },
    // Custom sectie voor StructuredJsonField
    customComponents: {
        StructuredJsonField: {
            roles: {
                assistant: {
                    bgColor: 'white',
                    textColor: 'default',
                    avatarColor: '#368F8B',
                },
                user: {
                    bgColor: '#f7f7f7',
                    textColor: 'default',
                    avatarColor: '#FC8077',
                },
                system: {
                    bgColor: 'white',
                    textColor: '#777777',
                    avatarColor: '#777777',
                },
                tool: {
                    bgColor: '#fff8f7',
                    textColor: '#777777',
                    avatarColor: '#777777',
                },
                default: {
                    bgColor: 'white',
                    textColor: 'default',
                    avatarColor: '#999999',
                }
            }
        }
    }
});

// Dark theme
export const myDarkTheme = deepmerge(defaultDarkTheme, {
    components: {
        MuiTextField: {
            defaultProps: {
                variant: 'outlined',
            },
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: 'rgba(255, 255, 255, 0.23)',
                            borderWidth: '1px',
                        },
                        '&:hover fieldset': {
                            borderColor: '#368F8B',
                            borderWidth: '1px',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#368F8B',
                            borderWidth: '1px',
                        },
                        backgroundColor: '#1e1e1e',
                    },
                    '&.custom-multiline .MuiInputBase-inputMultiline': {
                        minHeight: '50px',
                        fontSize: '1rem',
                    }
                }
            }            
        },
        MuiDrawer: {
            styleOverrides: {
                docked: {
                    borderRight: '1px solid rgba(0, 0, 0, 0.12)', // Voeg een rechterrand toe
                    marginRight: '10px',
                },
            },
        },  
        MuiButton: {
            styleOverrides: {
                root: {
                    boxShadow: 'none', // remove app bar shadow
                    textTransform: 'capitalize',
                },
            }
        },
        RaSidebar: {
            styleOverrides: {
                docked: {
                    display: 'none'
                }
            }
        },              
    },
    sidebar: {
        width: 210, 
        closedWidth: 55, 
    },
    
    customComponents: {
        StructuredJsonField: {
            roles: {
                assistant: {
                    bgColor: '#1e1e1e',
                    textColor: '#ffffff',
                    avatarColor: '#368F8B',
                },
                user: {
                    bgColor: '#2d2d2d',
                    textColor: '#ffffff',
                    avatarColor: '#FC8077',
                },
                system: {
                    bgColor: '#1e1e1e',
                    textColor: '#a0a0a0',
                    avatarColor: '#666666',
                },
                tool: {
                    bgColor: '#252525',
                    textColor: '#a0a0a0',
                    avatarColor: '#666666',
                },
                default: {
                    bgColor: '#1e1e1e',
                    textColor: '#ffffff',
                    avatarColor: '#666666',
                }
            }
        }
    }
});
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Call, Device} from '@twilio/voice-sdk';
import { useDataProvider, useGetIdentity } from 'react-admin';
import { Box, Button, Chip, CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import CallEndIcon from '@mui/icons-material/CallEnd';
import MicIcon from '@mui/icons-material/Mic';

interface VoiceCallProps {
  assistantId: number;
}

interface TwilioTokenResponse {
  data: {
    token: string;
  };
}

const VoiceCall: React.FC<VoiceCallProps> = ({ assistantId }) => {
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [callStatus, setCallStatus] = useState<string>('');
  const [isDeviceReady, setIsDeviceReady] = useState<boolean>(false);
  const deviceRef = useRef<Device | null>(null);
  const currentCallRef = useRef<Call | null>(null);
  const dataProvider = useDataProvider();
  const { identity } = useGetIdentity();
  const [timer, setTimer] = useState<number>(0);
  const timerRef = useRef<NodeJS.Timeout | null>(null);


  useEffect(() => {
    if (callStatus === 'Accepted') {
      setTimer(0);
      timerRef.current = setInterval(() => {
        setTimer(prevTimer => prevTimer + 1);
      }, 1000); 
    } else {
      clearInterval(timerRef.current!);
      timerRef.current = null;
    }

    return () => {
      clearInterval(timerRef.current!);
    };
  }, [callStatus]);

  const formatTime = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  };

  const fetchToken = useCallback(async (): Promise<string | null> => {
    try {
      const response: TwilioTokenResponse = await dataProvider.custom(`twilio/token?identity=${identity.id}&assistant_id=${assistantId}`, { 
        method: 'POST',
      });

      if (response?.data?.token) {
        return response.data.token;
      } else {
        throw new Error('Token not found in the response');
      }
    } catch (error) {
      console.error('Error fetching token:', error);
      setCallStatus('Error: Failed to fetch token');
      return null;
    }
  }, [dataProvider, assistantId]);

  const setupDevice = useCallback(async () => {
    const token = await fetchToken();
    if (!token) return;

    if (deviceRef.current) {
      deviceRef.current.destroy();
    }

    deviceRef.current = new Device(token, {
      codecPreferences: ['opus', 'pcmu'] as any,
      logLevel: 0,
      edge: 'dublin',
      
    });

    deviceRef.current.on('registered', () => {
      setIsDeviceReady(true);
      setCallStatus('Device ready');
    });

    deviceRef.current.on('error', async (error: any) => {
      console.error('Device error:', error);
      setCallStatus('Error: ' + error.message);
      setIsDeviceReady(false);
      if (error.code === 31204 || error.code === 20101) { // JWT is invalid or Access Token Invalid
        await updateToken();
      }
    });

    deviceRef.current.on('tokenWillExpire', updateToken);

    try {
      await deviceRef.current.register();
    } catch (error) {
      console.error('Failed to register device:', error);
      setCallStatus('Failed to register device');
    }
  }, [fetchToken]);

  const updateToken = useCallback(async () => {
    const newToken = await fetchToken();
    if (newToken && deviceRef.current) {
      await deviceRef.current.updateToken(newToken);
    }
  }, [fetchToken]);

  useEffect(() => {
    setupDevice();

    return () => {
      if (deviceRef.current) {
        deviceRef.current.destroy();
      }
    };
  }, [setupDevice]);

  const handleDtmf = useCallback((digit: string) => {
    if (currentCallRef.current && currentCallRef.current.status() === 'open') {
      currentCallRef.current.sendDigits(digit);
    } else {
      console.error('No active call to send DTMF');
    }
  }, []);

  const handleCall = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    try {
      if (!deviceRef.current || !isDeviceReady) {
        throw new Error('Device not ready');
      }

      if (callStatus === 'Accepted') {
        deviceRef.current.disconnectAll();
        setCallStatus('Disconnected');
        return;
      }

      setCallStatus('Initiating call...');
      const call = await deviceRef.current.connect({ params: { To: phoneNumber } });
      currentCallRef.current = call;
      
      call.on('accept', () => setCallStatus('Accepted'));
      call.on('ringing', () => setCallStatus('Ringing'));
      call.on('connected', () => setCallStatus('Connected'));
      call.on('disconnect', () => setCallStatus('Disconnected'));
      call.on('cancel', () => setCallStatus('Cancelled'));

    } catch (error: any) {
      console.error('Error making call:', error);
      setCallStatus('Error: ' + error.message);
    }
  };



  return (
    <Box     
      display="flex"
      justifyContent={'center'}
      alignItems={'center'}
      width={'100%'}
      flexDirection={'column'}
      border={'1px solid'}
      borderColor={'divider'}
      borderRadius={'10px'}
      mt={2}
      pt={2}
      >
         <Typography variant='h3'>{formatTime(timer)}</Typography>
        <Box width="100%" maxWidth="300px" m={3}>
          <Grid container spacing={2}>
            {['1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '0', '#'].map((digit) => (
              <Grid item xs={4} key={digit}>
                <Button variant="outlined" fullWidth  onClick={() => handleDtmf(digit)}  sx={{ 
                  height: '64px',  
                  fontSize: '1.25rem',
                  borderRadius: '10%',
                }}> {digit}</Button>
              </Grid>
            ))}
          </Grid>
      </Box>
      {(callStatus === 'Device ready' || callStatus === 'Disconnected') && (
        <IconButton
          onClick={handleCall}
          size='large'
          sx={{
            backgroundColor: 'green',
            '&:hover': {
              backgroundColor: 'darkgreen',
            },
            width: 56,
            height: 56,
            mb: 3,
          }}
        >
          <CallIcon sx={{ color: 'white' }} />
        </IconButton>
      )}
      {callStatus === 'Accepted' && (
        <IconButton
          onClick={handleCall}
          size='large'
          sx={{
            backgroundColor: 'red',
            '&:hover': {
              backgroundColor: 'darkred',
            },
            width: 56,
            height: 56,
            mb: 3,
          }}
        >
          <CallEndIcon sx={{ color: 'white' }} />
        </IconButton>
      )}
      {callStatus === 'Ringing' && (
        <CircularProgress sx={
          {
            mb: 3,
          }
        }/>
      )}
      
    </Box>
  );
};

export default VoiceCall;

import { Form, ReferenceArrayInput, AutocompleteArrayInput, useTranslate } from "react-admin";
import { Typography, Box, Drawer, Button } from "@mui/material";
import { DateRangeFilter } from './DateRangeFilter';
import FilterListIcon from '@mui/icons-material/FilterList';
import { ActiveFilters } from './ActiveFilters';

interface DashboardFiltersProps {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
  filters: any;
  onFilterChange: (filters: any) => void;
  permissions: string;
  activeSection?: string | null;
  onClearFilters: () => void;
}

export const DashboardFilters = ({ 
  open, 
  onClose, 
  onOpen, 
  filters, 
  onFilterChange, 
  permissions,
  onClearFilters
}: DashboardFiltersProps) => {
  const translate = useTranslate();
  const filterToQuery = (searchText: any) => ({ name: `%${searchText}%` });

  return (
    <>
      <Box sx={{ 
        display: 'flex', 
        flexDirection: { xs: 'column', sm: 'row' },
        justifyContent: 'space-between',
        alignItems: { xs: 'stretch', sm: 'center' },
        gap: 1,
        mb: 0
      }}>
        <Box sx={{ 
          order: { xs: 2, sm: 1 },
          flex: 1,
          display: 'flex',
          alignItems: 'center'
        }}>
          {!open && (Object.keys(filters).length > 0 || !filters.start_date) && (
            <ActiveFilters 
              filters={filters}
              onFilterChange={onFilterChange}
              onOpenFilters={() => {
                onOpen();
              }}
            />
          )}
        </Box>
        
        <Box sx={{ 
          display: 'flex',
          gap: 1,
          order: { xs: 1, sm: 2 },
          justifyContent: 'flex-end'
        }}>
          {Object.values(filters).some(value => 
            value !== undefined && 
            (!Array.isArray(value) || value.length > 0)
          ) && (
            <Button
              onClick={onClearFilters}
              variant="outlined"
            >
              {translate("resources.generic.clear_filters")}
            </Button>
          )}
          <Button
            startIcon={<FilterListIcon />}
            onClick={onOpen}
            variant="outlined"
          >
            {translate("resources.generic.open_filters")}
          </Button>
        </Box>
      </Box>

      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        sx={{
          '& .MuiDrawer-paper': { 
            width: '400px',
            padding: 2,
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column' // Zorgt ervoor dat we flexbox kunnen gebruiken voor layout
          },
        }}
      >
        <Box sx={{ p: 2, flex: 1, display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h6" sx={{ mb: 3 }}>
            {translate("resources.generic.filters")}
          </Typography>

          <Form>
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              gap: 2,
              flex: 1 
            }}>
              <DateRangeFilter 
                onChange={(dates) => onFilterChange({ 
                  start_date: dates.start ? new Date(dates.start) : undefined,
                  end_date: dates.end ? new Date(dates.end) : undefined,
                  shortcutLabel: dates.shortcutLabel
                })} 
                value={filters.start_date ? [
                  new Date(filters.start_date),
                  new Date(filters.end_date || filters.start_date)
                ] : undefined}
              />
              <ReferenceArrayInput source="assistant_id" reference="assistants">
                <AutocompleteArrayInput 
                  optionText="name"
                  fullWidth
                  helperText={false}
                  filterToQuery={filterToQuery} 
                  onChange={(data) => onFilterChange({ assistant_ids: data })} 
                  defaultValue={filters.assistant_ids}
                />
              </ReferenceArrayInput>
              {['admin', 'partner'].includes(permissions) && 
                <ReferenceArrayInput source="customer_id" reference="customers">
                  <AutocompleteArrayInput 
                    optionText="name"
                    fullWidth
                    helperText={false}
                    filterToQuery={filterToQuery} 
                    onChange={(data) => onFilterChange({ customer_ids: data })} 
                    defaultValue={filters.customer_ids}
                  />
                </ReferenceArrayInput>
              }
            </Box>
          </Form>
        </Box>

        {/* Footer met knop */}
        <Box sx={{ 
          p: 2, 
          borderTop: 1, 
          borderColor: 'divider',
          display: 'flex', 
          justifyContent: 'flex-end'
        }}>
          <Button
            variant="contained"
            onClick={onClose}
            color="primary"
          >
            {translate("resources.generic.close")}
          </Button>
        </Box>
      </Drawer>
    </>
  );
}; 
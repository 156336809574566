import * as React from 'react';
import { Menu, MenuProps, usePermissions } from 'react-admin';
import { JSX } from 'react/jsx-runtime';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';

const CustomMenu = (props: JSX.IntrinsicAttributes & MenuProps) => {
    const { permissions } = usePermissions();

    return (
        <Menu {...props}>
            <Menu.DashboardItem />
            <Menu.ResourceItems />
            {permissions === 'admin' && (
                <Menu.Item
                    to="/onboarding"
                    primaryText="Onboarding"
                    leftIcon={<SettingsSuggestIcon />}
                />
            )}
        </Menu>
    );
};

export default CustomMenu;
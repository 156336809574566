import { useTranslate } from 'react-admin';
import { Typography } from '@mui/material';

//import { CallFlow } from './CallFlow';
export const FieldHeader = ({ title, description }) => {
    const translate = useTranslate();
    return (
        <div style={{ marginBottom: 6 }}>
            <Typography variant="h6"><strong>{translate(title)}</strong></Typography>
            <Typography variant="body2" color="textSecondary">
                {translate(description)}
            </Typography>
        </div>
    )
}

import React from 'react';
import { Button } from '@mui/material';
import { useRecordContext } from 'react-admin';
import PhoneIcon from '@mui/icons-material/Phone';

interface CallAssistantButtonProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement>, id: number) => void;
}

const CallAssistantButton: React.FC<CallAssistantButtonProps> = ({ onClick }) => {
  const record = useRecordContext<any>();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (record && record.id) {
      onClick(event, record.id);
    }
  };

  return (
    <Button 
      size="small" 
      onClick={handleClick} 
      variant="outlined" 
      startIcon={<PhoneIcon />}
    >
      Call
    </Button>
  );
};

export default CallAssistantButton;
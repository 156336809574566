import { createSvgIcon } from "@mui/material"

const PollieIcon = createSvgIcon(
<svg width="375" height="387" viewBox="0 0 375 387" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M187.17 374.467C83.9621 374.467 0 290.501 0 187.293C0 84.0844 83.9621 0.112549 187.17 0.112549C290.379 0.112549 374.355 84.0825 374.355 187.293C374.355 290.503 290.385 374.467 187.17 374.467Z" fill="#358F8B"/>
<path d="M216.978 280.83H157.376V320.832H216.978V280.83Z" fill="#CDE0DC"/>
<path d="M276.627 231.693C296.23 231.693 312.264 227.06 312.264 200.515C312.264 173.97 296.23 169.339 276.627 169.339V231.695V231.693Z" fill="#CDE0DC"/>
<path d="M75.4575 216.451C75.4575 255.65 107.526 287.718 146.717 287.718H227.636C266.83 287.718 298.899 255.652 298.899 216.451V196.068C298.899 156.867 266.832 124.801 227.636 124.801H146.717C107.526 124.801 75.4575 156.865 75.4575 196.068V216.451Z" fill="#FFFCFD"/>
<path d="M227.636 249.987C251.747 249.987 271.364 230.37 271.364 206.261C271.364 182.145 251.747 162.532 227.636 162.532H146.717C122.612 162.532 102.991 182.145 102.991 206.261C102.991 230.37 122.612 249.987 146.717 249.987H227.636Z" fill="#CDE0DC"/>
<path d="M218.353 151.526C218.353 131.926 213.721 115.892 187.174 115.892C160.628 115.892 155.998 131.926 155.998 151.526H218.355H218.353Z" fill="#FFFCFD"/>
<path d="M173.709 76.3428C173.709 68.9011 179.745 62.8713 187.175 62.8713C194.604 62.8713 200.66 68.9011 200.66 76.3428C200.66 83.7844 194.624 89.822 187.175 89.822C179.725 89.822 173.709 83.7942 173.709 76.3428Z" fill="#FFFCFD"/>
<path d="M196.53 79.0998C196.53 71.6523 190.494 65.6245 183.066 65.6245C181.198 65.6245 179.415 66.0068 177.799 66.6912C180.225 64.3303 183.539 62.8713 187.198 62.8713C194.626 62.8713 200.662 68.9011 200.662 76.3428C200.662 81.9313 197.273 86.716 192.445 88.7631C194.967 86.314 196.532 82.8961 196.532 79.0998H196.53Z" fill="#CDE0DC"/>
<path d="M191.678 86.0903H182.68V119.885H191.678V86.0903Z" fill="#FFFCFD"/>
<path d="M86.8762 169.976C72.54 171.935 62.0903 179.086 62.0903 200.513C62.0903 220.795 71.589 228.327 84.8996 230.721C87.3233 231.156 89.1273 169.668 86.8762 169.976Z" fill="#FFFCFD"/>
<path d="M159.678 206.261C159.678 199.79 154.425 194.554 147.978 194.554C141.53 194.554 136.265 199.79 136.265 206.261C136.265 212.732 141.505 217.963 147.978 217.963C154.451 217.963 159.678 212.732 159.678 206.261Z" fill="#358F8B"/>
<path d="M238.091 206.261C238.091 199.79 232.854 194.554 226.389 194.554C219.924 194.554 214.68 199.79 214.68 206.261C214.68 212.732 219.918 217.963 226.389 217.963C232.86 217.963 238.091 212.728 238.091 206.261Z" fill="#358F8B"/>
<path d="M255.824 360.713C255.824 328.839 231.526 309.053 187.18 309.053C142.834 309.053 118.533 328.839 118.533 360.713C118.533 392.588 155.302 386.172 187.18 386.172C219.059 386.172 255.824 392.578 255.824 360.713Z" fill="#FFFCFD"/>
<path d="M242.097 360.713C242.097 362.757 241.946 364.641 241.654 366.382C251.178 363.419 255.818 361.433 255.818 361.433C255.822 361.196 255.824 360.956 255.824 360.715C255.824 336.926 242.289 319.872 216.988 312.75C216.988 312.75 242.097 321.201 242.097 360.715V360.713Z" fill="#CDE0DC"/>
</svg>,'PollieIcon');

export default PollieIcon;
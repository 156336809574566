// in src/dataProvider.ts
import { DataProvider, HttpError } from "react-admin";
import queryString from "query-string";
import axios, { AxiosError } from "axios";

const apiUrl = import.meta.env.VITE_API_URL

export const sendPost = async (resource: string, method: string, body?: any) => {
    const auth = localStorage.getItem('auth');
    if (!auth) {
        return Promise.reject();
    }
    const token = auth ? JSON.parse(auth).access_token : null;

    try {
        const response = await axios({
            method: 'post',
            url: `${apiUrl}/${resource}/ra${method}`,
            data: body,
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });

        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const axiosError = error as AxiosError;
            const status = axiosError.response?.status || 500;
            const statusText = axiosError.response?.statusText || 'Internal Server Error';
            const body = axiosError.response?.data;

            let message = statusText;
            if (typeof body === 'object' && body !== null && 'message' in body) {
                message = body.message as string;
            }

            throw new HttpError(message, status, body);
        } else {
            throw new Error('An unexpected error occurred');
        }
    }
};

const convertFileToBase64 = (file: File): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = reject;
        reader.readAsDataURL(file);
    });

    const handleFileUpload = async (data: any) => {
        const promises: Promise<void>[] = [];
    
        const recursiveConvert = (obj: any) => {
            Object.keys(obj).forEach(key => {
                if (obj[key] && obj[key].rawFile instanceof File) {
                    promises.push(
                        convertFileToBase64(obj[key].rawFile).then(base64Image => {
                            obj[key] = {
                                src: base64Image,
                                title: obj[key].rawFile.name, // or any other logic to determine the title
                                //rawFile: obj[key].rawFile
                            };
                        })
                    );
                } else if (obj[key] && typeof obj[key] === 'object') {
                    recursiveConvert(obj[key]);
                }
            });
        };
    
        recursiveConvert(data);
        await Promise.all(promises);
    };

export const baseDataProvider: DataProvider = {
    getList: (resource: string, params: any) => {
        const { page, perPage } = params.pagination
        const { field, order } = params.sort

        const query = {
            sort: JSON.stringify([field, order]),
            range_: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify(params.filter),
        }

        return sendPost(resource, `/getList?${queryString.stringify(query)}`)
    },

    getOne: async (resource: string, params: any) => {
        return await sendPost(resource, '/getOne/' + params.id)
    },

    getMany: (resource: string, params: any) => {
        const query = {
            filter: JSON.stringify({ id: params.ids}),
        }

        return sendPost(resource, `/getMany?${queryString.stringify(query)}`)
    },

    getManyReference: (resource: string, params: any): any => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
    
        const query = {
            sort: JSON.stringify([field, order]),
            range_: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id, // Voeg de target parameter toe aan de filter
            }),
        };
    
        return sendPost(resource, `/getList?${queryString.stringify(query)}`);
    },
    

    create: async (resource: string, params: any) => {
        await handleFileUpload(params.data);
        return await sendPost(resource, '/create', { 
            ...params.data
        });
    },

    update: async (resource: string, params: any) => {
        await handleFileUpload(params.data);
        return await sendPost(resource, `/update/${params.id}`, params.data);
    },

    updateMany: async (resource: string, params: any) => {
        const query = {
            filter: JSON.stringify({ id: params.ids}),
        }

        return await sendPost(resource, `/updateMany?${queryString.stringify(query)}`, params.data)
    },

    delete: (resource: string, params: any) => {
        return sendPost(resource, '/delete/' + params.id)
    },

    deleteMany: (resource: string, params: any) => {
        const query = {
            filter: JSON.stringify({ id: params.ids}),
        }

        return sendPost(resource, `/deleteMany?${queryString.stringify(query)}` )
    },
    
    getDefaults: (resource: string) => {
        return sendPost(resource, `/getDefaults` )
    },

    getRevisions: async (resource, params) => {
        const { recordId } = params;
        // ...
        return { data: revisions };
    },
    addRevision: async (resource, params) => {
        const { recordId, data, authorId, message, description } = params;
        // ...
        return { data: revision };
    },
    deleteRevisions: async resource => {
        const { recordId } = params;
        // ...
        return { data: deletedRevisionIds };
    },

    custom: async (resource: string, params: any) => {

        const auth = localStorage.getItem('auth');
        const token = auth ? JSON.parse(auth).access_token : null;
    
        const response = await axios({
            method: params.method ? params.method : 'post',
            url: apiUrl + '/' + resource,
            params: params.data,
            data: params.postdata,
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        return { data: response.data };

    }
}
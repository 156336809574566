// in src/dataProvider.ts
import { baseDataProvider } from './baseDataProvider';
import { DataProvider } from 'react-admin';

export const addRevisionMethodsBasedOnSingleResource = (
    dataProvider: DataProvider,
    { resourceName } = { resourceName: 'revisions' }
) => ({
    ...dataProvider,
    getRevisions: (resource, { recordId }) =>
        dataProvider.getList(resourceName, {
            filter: { resource: 'assistants', record_id: recordId},
            sort: { field: 'date', order: 'DESC' },
            pagination: { page: 1, perPage: 100 },
        }),
    addRevision: (
        resource,
        { recordId, data, authorId, message, description }
    ) =>
        dataProvider.create(resourceName, {
            data: {
                resource,
                record_id: recordId,
                date: new Date().toISOString(),
                message,
                description,
                author_id: authorId,
                data,
            },
        }),
    deleteRevisions: async (resource, { recordId }) => {
        const { data: deletedRevisions } = await dataProvider.getList(
            resourceName,
            {
                filter: { resource, record_id: recordId },
                sort: { field: 'date', order: 'DESC' },
                pagination: { page: 1, perPage: 100 },
            }
        );
        return dataProvider.deleteMany(resourceName, {
            ids: deletedRevisions.map(revision => revision.id),
        });
    },
});


export const dataProvider = addRevisionMethodsBasedOnSingleResource(
    baseDataProvider,
    { resourceName: 'revisions' }
);

import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@mui/material';
import LexiconEdit from './LexiconEdit';
import { FieldHeader } from '../FieldHeader';

const LexiconEditDialog = (props) => {
  const [open, setOpen] = useState(false);
  const translate = useTranslate();


  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <FieldHeader title="resources.assistants.fields.lexicon" description="resources.assistants.descriptions.lexicon" />
      <Button variant="outlined" color="primary" onClick={handleOpen}>
      {translate('resources.assistants.fields.edit_pronunciations')}
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>{translate('resources.assistants.fields.edit_pronunciations')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{translate('resources.assistants.descriptions.lexicon')}</DialogContentText>
          <LexiconEdit {...props} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
          {translate('resources.generic.close')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LexiconEditDialog;
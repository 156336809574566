import { useNavigate } from 'react-router-dom';

import { useNotify, Toolbar, SaveButton, Edit, SimpleForm, TextInput, required, email, PasswordInput, minLength, useAuthenticated, BooleanInput, regex, useTranslate } from 'react-admin';
import { useRefresh, useGetIdentity } from 'react-admin';
import { useDataProvider } from 'react-admin';
import PhoneNumberInput from './PhoneNumberInput';

export const equalToPassword = (value: any, allValues: any) => {
  if (value !== allValues.new_password) {
      return 'The two passwords must match';
  }
}
const ProfileEditToolbar = () => {
  const refresh = useRefresh();

  return (  
    <Toolbar>
      <SaveButton onClick={refresh} />
    </Toolbar>
  )
};


export const Profile = () => {
  
  useAuthenticated(); 

  const translate = useTranslate();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const navigate = useNavigate();
  const { data, refetch, isLoading, error } = useGetIdentity();

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error: {error.message}</div>;
  }


  const handleSubmit = (values) => {
    console.log("handleSubmit", values);
    const updatedValues = { ...values, email: data.email };
    dataProvider.custom('users/ra/update/'+data.id, {
      method: 'POST',
      postdata: updatedValues
    }).then(() => {
        notify('resources.generic.profile_updated', { type: 'info' }); // Toon de notificatie
        refetch();
        navigate('/'); // Navigeer naar de hoofdpagina na succes
    });
  };

  return (
      <Edit title="resources.generic.my_profile" id={data.id} resource="users" mutationMode='pessimistic' redirect="/">
      <SimpleForm toolbar={<ProfileEditToolbar  />} sx={{ maxWidth: 400 }} onSubmit={handleSubmit}>
        <TextInput source="first_name" validate={required()} fullWidth />
        <TextInput source="last_name" validate={required()} fullWidth />
        <TextInput source="email" type="email" validate={[required(),email()]} disabled fullWidth/>
        <PhoneNumberInput source="phone_number" validate={required()} fullWidth/>
        <BooleanInput
            source="two_factor_enabled"
            label="resources.users.fields.requires_2fa"
            validate={(value, allValues) => {
              if (value && (!allValues.phone_number || allValues.phone_number.trim() === '')) {
                return 'app.validation.phone_number_required_for_2fa';
              }
              return undefined;
            }}
        />
        <PasswordInput source="new_password"
        autoComplete='new-password'
        validate={[
          minLength(8, translate('app.validation.minLength')),
          regex(/[A-Z]/, translate('app.validation.password.uppercase')),
          regex(/[!@#$%^&*]/, translate('app.validation.password.special'))
        ]}
        label="resources.users.new_password" fullWidth/>
        <PasswordInput source="new_password_confirm" validate={equalToPassword} autoComplete='no' label="resources.users.new_password_confirm" fullWidth/>
      </SimpleForm>
    </Edit>
  );
};

export default Profile;
import * as React from 'react';
import { useRecordContext } from 'react-admin';

interface DurationFieldProps {
  source: string;
  label?: string;
}

const DurationField: React.FC<DurationFieldProps> = ({ source, label }) => {
  const record = useRecordContext();
  if (!record) {
    return null;
  }

  const formatDuration = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const value = record[source];
  const formattedDuration = value != null ? formatDuration(value) : '';

  return (
    <span>
      {formattedDuration}
    </span>
  );
};

export default DurationField;

import { useInput } from 'react-admin';
import { useCallback, useRef, useEffect } from 'react';
import { MDXEditor, BlockTypeSelect, quotePlugin, tablePlugin, codeBlockPlugin, codeMirrorPlugin, headingsPlugin, UndoRedo, BoldItalicUnderlineToggles, InsertCodeBlock, CodeToggle, ListsToggle, listsPlugin, toolbarPlugin, InsertTable, diffSourcePlugin, DiffSourceToggleWrapper } from '@mdxeditor/editor'
import '@mdxeditor/editor/style.css'
import './mdxeditor.css'
import './mdxeditor-dark.css'
import { useTheme } from '@mui/material/styles';

const CustomMDXEditor = ({
    minHeight = '200px',
    maxHeight = '600px',
    ...props
  }) => {
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';

    function preprocessMarkdown(markdown:string) {
      return markdown?.replace(/(?<!\\)<=/g, '\\<=') || '';
    }

    const handleError = (payload:any) => {
        console.error('Markdown Parsing Error:', payload);        
      };

    const {
        field, // destructuring field from useInput
        fieldState: { invalid, isTouched, isDirty },
        formState: { errors }
    } = useInput(props);

    const { value, onChange } = field;
    const editorRef = useRef<any>(null);
    const originalValueRef = useRef(value);

    // Update editor content when value changes externally
    useEffect(() => {
        if (value !== undefined && editorRef.current) {
            originalValueRef.current = value;
            editorRef.current.setMarkdown(preprocessMarkdown(value));
        }
    }, [value]);

    // Memoize the onChange handler
    const handleEditorChange = useCallback(
      (newMarkdown:string) => {
        setTimeout(() => {
          onChange(newMarkdown);
        }, 0);
      },
      [onChange]
    );
  
    return (
        <div style={{marginBottom:'20px'}}>
            <MDXEditor    
                ref={editorRef}
                className={`custom-mdx-editor ${isDarkMode ? 'dark-theme' : ''}`}
                markdown={preprocessMarkdown(value)}            
                onError={handleError}
                onChange={handleEditorChange}
                plugins={[
                    quotePlugin(),
                    diffSourcePlugin({ diffMarkdown: preprocessMarkdown(originalValueRef.current), viewMode: 'rich-text' }),
                    tablePlugin(),
                    listsPlugin(),        
                    headingsPlugin(),     
                    codeBlockPlugin({defaultCodeBlockLanguage: 'jinja'}),
                    codeMirrorPlugin({ codeBlockLanguages: { jinja: 'Template' } }),
                    toolbarPlugin({
                        toolbarContents: () => (
                            <>
                            <DiffSourceToggleWrapper>
                                <UndoRedo />
                                <BlockTypeSelect />
                                <BoldItalicUnderlineToggles />
                                <CodeToggle />
                                <InsertCodeBlock />
                                <ListsToggle options={['bullet']} />
                                <InsertTable />
                              </DiffSourceToggleWrapper>
                            </>
                        )
                    })
                ]}
            />
            {isTouched && invalid && <span>{errors[props.source]?.message}</span>}
        </div>
    );
};

export default CustomMDXEditor;
